// logoSvg.js

import React from 'react';

const Logo = () => (
	<svg viewBox="0 0 150 450" style={{ fill:"none",strokeWidth:"20px",strokeLinecap:"round",height:"inherit" }}>
		<path d="M 74 125 a 50 50 0 1 1 35 -85
				 M 74 225 a 50 50 0 0 1 0 -100
				 M 74 225 a 50 50 180 0 1 0 100
				 M 24 375 a 50 50 90 0 1 50 -50
				 M 24 375 v 50" />
	</svg>
)

export default Logo;