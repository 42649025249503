// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyD577Mex0mVgjkmdKOze2GLZt7okL_OR08",

  authDomain: "elastic-potential.firebaseapp.com",

  projectId: "elastic-potential",

  storageBucket: "elastic-potential.appspot.com",

  messagingSenderId: "293772556797",

  appId: "1:293772556797:web:484d9fadd418c6c6c74194",

  measurementId: "G-DVQ4SJQDZC"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);