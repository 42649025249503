import { React, useState } from 'react';

import './App.css';

import background from './static/rocks.jpg';
import Logo from './components/svg/logoSvg.js';
import Welcome from './components/welcome.js';

function App() {
  
	const [stage, setStage] = useState(0);
  
  	return (
    	<div className="App">
	  		<div className="back" style={{ backgroundImage: `url(${background})` }}></div>
			{/* HEADER */}
			<div className="header">
	  			<h1>elastic</h1>
		  		<div className="logo-header">
		  			<Logo />
				</div>
		  		<h1>potential</h1>
		  	</div>
		{/* WELCOME/INTRO */}
			<Welcome />
		
    	</div>
  );
}

export default App;
