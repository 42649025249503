// welcome.js

import React from 'react';
import Energy_symbols from '../static/energy_symbols.svg'
import Energy_io from '../static/energy_in_out.svg'
import Energy_cost from '../static/energy_cost.svg'

const Welcome = () => (
	<>
	<div className="welcome">
		<div className="w1">
			<div className="centered">
				<h3> Today's energy needs </h3> {/*'*/}
				<h3> require unique solutions. </h3>
				<br></br>
				<h3> Renewables add flexibility </h3>
				<h3> in power generation, but </h3>
				<h3> the rest of <span className="redtxt">the system </span></h3>
				<h3><span className="redtxt"> is seriously outdated</span>.</h3>
			</div>
		</div>
		<div className="imgdiv img1"><img src={Energy_symbols} /></div>
		<div className="w2">
			<div className="centered">
				<h3> Harnessing energy </h3>
				<h3> must be separated </h3>
				<h3> from its usage. For that </h3>
				<h3><span className="redtxt"> we need ways to store </span></h3>
				<h3><span className="redtxt">energy</span>. Sounds great, </h3>
				<h3> so what's the holdup? </h3> {/*'*/}
			</div>
		</div>
		<div className="imgdiv img2"><img src={Energy_io} /></div>
		<div className="w3">
			<div className="centered">
				<h3> Despite all the forward</h3>
				<h3> progress and need, cost</h3>
				<h3> is always the driver. The </h3>
				<h3><span className="redtxt">energy storage</span> required </h3>
				<h3><span className="redtxt">must be cheap</span> enough </h3>
				<h3><span className="redtxt">for large scale adoption</span>.</h3>
			</div>
		</div>
		<div className="imgdiv img3"><img src={Energy_cost} /></div>
		<div className="w4">
			<div className="centered">
				<h3> So what if I told you just</h3>
				<h3> such <span className="redtxt">a solution exists</span>?</h3>
			</div>
		</div>
		<div className="w4">>
			<div className="centered">
				<p>&copy; {new Date().getFullYear()} Elastic Potential LLC</p>
				<br></br>
			</div>
			</div>
	</div>
	</>
)

export default Welcome;